body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root,
.App,
.StartPage,
.GameClient {
  width: 100%;
  height: 100%;
}


.cursor-pointer {
  cursor: pointer;
}

.icon-hover {
  transition: transform 300ms ease;
  transform: scale(1);
}

.icon-hover:hover {
  transform: scale(1.1);
}

a,
a:visited {
  color: #555;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.appear-enter {
  transform: scale(0.3);
}

.appear-enter-active {
  transform: scale(1);
  transition: transform 500ms ease;
}

.appear-exit {
  transform: scale(1);
}

.appear-exit-active {
  transform: scale(0.1);
  transition: transform 500ms ease;
}

.label-link {
  cursor: pointer;
}

.label-link:hover {
  text-decoration: underline;
  transition: transform 300s ease;
}

.label-link img {
  transition: transform 300s ease;
  transform: scale(1);
}

.label-link:hover img {
  transform: scale(1.1);
}