.Select {
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  cursor: default;
}

.Select::-ms-expand {
  display: none;
}

.Select {
  padding: 5px 20px 5px 8px;
  border: 1px solid #857E77;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='292.4' height='292.4'%3E%3Cpath fill='%23857E77' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 9px;
  color: #454545;
}

.Select:hover {
  border-color: #777;
}

.Select:focus {
  border-color: #999;
  box-shadow: 0 0 1px 2px #6db4ff;
  outline: none;
}

.Select:disabled,
.Select[aria-disabled="true"] {
  cursor: not-allowed;
  background-color: rgba(211, 211, 211, 0.6);
  border-color: #aaa;
}

.Select:disabled:hover,
.Select[aria-disabled="true"]:hover {
  border-color: #aaa;
}

.Select .Select--placeholder {
  color: #777;
}